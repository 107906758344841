<template>
  <div>
    <el-page-header @back="$router.back()" :content="title">
    </el-page-header>
    <el-form v-loading="selLoading" style="padding:20px;" label-width="160px" size="small" inline class="form" ref="form" :model="form" :rules="rules">
      <el-form-item label="账户类型" prop="accountType">
        <el-select v-model="form.accountType">
          <el-option :value="0" label="企业账户"></el-option>
          <el-option :value="1" label="个人账户"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户类型" prop="userType">
        <el-select v-model="form.userType">
          <el-option :value="0" label="付费"></el-option>
          <el-option :value="1" label="试用"></el-option>
          <el-option :value="2" label="第三方渠道"></el-option>
        </el-select>
      </el-form-item>
      <br />
      <el-form-item label="组织类型" prop="organizationType">
        <el-select v-model="form.organizationType" :disabled="id?true:false">
          <el-option :value="1" label="企业组织"></el-option>
          <el-option :value="0" label="社区组织"></el-option>
        </el-select>
      </el-form-item>
      <br />
      <!-- 企业账户 -->
      <el-form-item label="公司名称" prop="companyName" :rules="{required:true,message:'公司名称必填'}">
        <el-input v-model="form.companyName"></el-input>
      </el-form-item>
      <br />
      <el-form-item label="公司简称" prop="companyAbbreviation" :rules="{required:true,message:'公司简称必填'}">
        <el-input v-model="form.companyAbbreviation"></el-input>
      </el-form-item>
      <br />
      <el-form-item label="社会统一编码" prop="socialUniformCoding">
        <el-input v-model="form.socialUniformCoding"></el-input>
      </el-form-item>
      <br />
      <el-form-item label="企业默认logo" prop="companyLogo">
        <span style="color:#999">图片比例：1*1</span>
        <jf-ossUpload-img :value="form.companyLogo" @result="(val)=>{form.companyLogo=val}" />
      </el-form-item>
      <br />
      <el-form-item label="平台logo" prop="companyLogo">
        <span style="color:#999">图片比例：150*45</span>
        <jf-ossUpload-img :value="form.platformLogo" @result="(val)=>{form.platformLogo=val}" />
      </el-form-item>
      <br />
      <el-form-item label="证件图片" prop="certificateImages">
        <jf-ossUpload-img :value="form.certificateImages" @result="(val)=>{form.certificateImages=val}" />
      </el-form-item>
      <br />
      <el-form-item label="管理员账户" prop="username">
        <el-input v-model="form.username"></el-input>
      </el-form-item>
      <br />
      <el-form-item label="管理员姓名" prop="adminName">
        <el-input v-model="form.adminName"></el-input>
      </el-form-item>
      <br />
      <el-form-item label="所属行业" prop="industrysId">
        <el-select v-model="form.industrysId" @change="industrysIdChange">
          <el-option v-for="item in industry" :key="item.id" :label="item.industryName" :value="item.id"></el-option>
        </el-select>

      </el-form-item>
      <el-form-item label="经营类型" prop="industrysManageId">
        <el-select v-model="form.industrysManageId">
          <el-option v-for="item in industrysManage" :key="item.id" :label="item.industryName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <br>
      <el-form-item label="企业负责人" prop="enterprisesOwners" :rules="{required:true,message:'企业负责人必填'}">
        <el-input v-model="form.enterprisesOwners"></el-input>
      </el-form-item>
      <br />
      <el-form-item label="职务" prop="duty">
        <el-input v-model="form.duty"></el-input>
      </el-form-item>
      <br />
      <el-form-item label="负责人手机号" prop="principalTelephone">
        <el-input v-model="form.principalTelephone"></el-input>
      </el-form-item>
      <br />
      <el-form-item label="所在地区" prop="areaId">
        <el-cascader clearable v-model="areaIds" @change="areaChange" :options="area" :show-all-levels="false" :props="{ expandTrigger: 'hover',label:'label',value:'id'}"></el-cascader>
      </el-form-item>
      <br />
      <el-form-item label="地址" prop="address">
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <br />
      <el-form-item label="软件对接人" prop="softwareConnector">
        <el-input v-model="form.softwareConnector"></el-input>
      </el-form-item>
      <el-form-item label="对接人手机号" prop="receiverTelephone">
        <el-input v-model="form.receiverTelephone"></el-input>
      </el-form-item>
      <br />
      <el-form-item label="购买人数包" prop="purchasersNumber">
        <el-input-number :min="0" v-model="form.purchasersNumber"></el-input-number>
      </el-form-item>
      <el-form-item label="存储空间配额" prop="storageSpaceQuota">
        <el-input-number :min="0" v-model="form.storageSpaceQuota"></el-input-number> &nbsp;G
      </el-form-item>
      <br />
      <el-form-item label="启动日期" prop="startDate">
        <el-date-picker value-format="yyyy-MM-dd" v-model="form.startDate" style="width:185px"></el-date-picker>
      </el-form-item>
      <el-form-item label="到期日期" prop="expiryDate">
        <el-date-picker value-format="yyyy-MM-dd" v-model="form.expiryDate" style="width:185px"></el-date-picker>
      </el-form-item>
      <br />
      <el-form-item label="显示轮播图数量">
        <el-input-number :min="0" v-model="form.showSlideshowQuantity"></el-input-number>
      </el-form-item>
       <br />
      <el-form-item label="开单人" prop="drawer">
        <el-input v-model="form.biller" maxlength="10"></el-input>
      </el-form-item>
      <br />
      <el-form-item label="功能模块" prop="moduleIds">
        <el-button size="mini" @click="chooseModulesShow = true">
          选择权限
        </el-button>
        管理平台{{ form.moduleIds ? form.moduleIds.length : 0 }}个项；
        App{{ form.appModuleIds ? form.appModuleIds.length : 0 }}个项
      </el-form-item>

      <br />
      <el-form-item label="积分类型" prop="pointsType">
        <el-radio v-model="form.pointsType" :label="0">B分</el-radio>
        <el-radio v-model="form.pointsType" :label="1">B分+A分</el-radio>
        <el-radio v-model="form.pointsType" :label="2">B分+A分+产值</el-radio>
      </el-form-item>
      <br />
      <el-form-item label="开启超标事件:">
        <el-switch v-model="form.isExceedNormIncident" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <br />
      <el-form-item label="开启临时事件:">
        <el-switch v-model="form.isTempIncident" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <br />
      <el-form-item label="开启创建打印队列:">
        <el-switch v-model="form.isPrintQueue" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <br />
      <el-form-item label="开启分享邀请:">
        <el-switch v-model="form.isShareUser" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <br />
      <el-form-item label="开启app考勤模块:">
        <el-switch v-model="form.isAppWorkAttendance" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <br />
      <el-form-item label="开启临时事件奖票:">
        <el-switch v-model="form.isTempTicket" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <br />
      <el-form-item label="不显示默认轮播图:">
        <el-switch v-model="form.isNotDefaultSlideshow" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <br />
      <el-form-item label="状态">
        <el-radio v-model="form.status" :label="0">冻结</el-radio>
        <el-radio v-model="form.status" :label="1" disabled>正常</el-radio>
      </el-form-item>
      <br />
      <el-form-item label="事件模板库">
        <span style="color:#409eff"> {{ template.tempName }}</span>
        <el-button size="mini" @click="chooseEventShow = true">{{ form.templateId ? "重新选择" : "选择事件模板" }}</el-button>
      </el-form-item>
     
    </el-form>
    <span class="dialog-footer">
      <el-button size="small" :loading="loading || selLoading" type="primary" @click="submit">确 定</el-button>
      <el-button size="small" @click="$router.back()">取 消</el-button>
    </span>
    <ChooseEvent :show.sync="chooseEventShow" @confirm="setTemplateId" />
    <ChooseModules :show.sync="chooseModulesShow" :moduleIds.sync="form.moduleIds" :appModuleIds.sync="form.appModuleIds" />

  </div>
</template>

<script>
import ChooseEvent from "./chooseEvent";
import ChooseModules from "./chooseModules.vue"

export default {
  components: {
    ChooseEvent, ChooseModules,
  },

  data() {
    return {
      chooseEventShow: false,
      chooseAppModulesShow: false,
      form: {
        moduleIds: [],
        appModuleIds: [],

      },
      loading: false,
      selLoading: false,
      rules: {
        organizationType: [
          { required: true, message: "组织类型必填", trigger: ["change"] },
        ],
        accountType: [
          { required: true, message: "账户类型必填", trigger: ["change"] },
        ],
        userType: [
          { required: true, message: "用户类型必填", trigger: ["change"] },
        ],
        username: [
          { required: true, message: "管理员账号必填" },
          { validator: this.validatePhone, trigger: ["change", "blur"] },
        ],
        adminName: [{ required: true, message: "管理员姓名必填" }],
        industrysId: [
          { required: true, message: "行业必填", trigger: ["change"] },
        ],
        industrysManageId: [
          { required: true, message: "经营类型必填", trigger: ["change"] },
        ],
        principalTelephone: [
          { required: true, message: "负责人手机号必填" },
          { validator: this.validatePhone, trigger: ["change", "blur"] },
        ],
        areaId: [{ required: true, message: "地区必填", trigger: ["change"] }],
        softwareConnector: [{ required: true, message: "软件对接人必填" }],
        receiverTelephone: [
          { required: true, message: "对接人手机号必填" },
          { validator: this.validatePhone, trigger: ["change", "blur"] },
        ],
        purchasersNumber: [{ required: true, message: "购买人数包必填" }],
        storageSpaceQuota: [{ required: true, message: "存储空间配额必填" }],
        startDate: [{ required: true, message: "启动日期必填" }],
        expiryDate: [{ required: true, message: "到期日期必填" }],
        moduleIds: [
          {
            required: true,
            validator: this.moduleIdsRule,
          },
        ],
      },
      id: "",
      // 地区选项
      area: [],
      // 行业选项-1级
      industry: [],
      // 行业选项-2级
      industrysManage: [],
      chooseModulesShow: false,
      // 模块选项
      modules: [],
      // 选择的事件模板
      template: {},

      // 选择的地区ids
      areaIds: [],
    };
  },
  watch: {},

  computed: {
    title() {
      return this.id ? "修改账户" : "添加账户";
    },
  },

  created() {
    this.id = this.$route.query.id;
    this.getIndustry();

    this.getArea();
  },
  mounted() {
    this.initForm();
  },

  methods: {
    // 验证手机号
    validatePhone(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }
    },
    //查询详情
    async sel() {
      this.selLoading = true;
      var res = await this.$get("/platform-admin/tenant/" + this.id);
      this.selLoading = false;
      if (res && res.code === 0) {
        this.form = res.data;
        /* 默认企业 */
        if (this.form.organizationType === null || this.form.organizationType === undefined) {
          this.form.organizationType = 1
        }
        /* 回显逻辑处理 */
        // 选择的地区ids
        this.areaIds = [
          this.form.provinceId,
          this.form.cityId,
          this.form.areaId,
        ];
        // 模板名称回显
        this.template = {
          tempName: this.form.tempName,
        };
        // 重置二级行业选项
        this.resetIndustrysManage(this.form.industrysId);

        this.$forceUpdate();
      }
    },
    // 验证选择模板
    moduleIdsRule(rule, value, callback) {
      console.log(!this.form.moduleIds || !this.form.moduleIds.length)
      if (!this.form.moduleIds || !this.form.moduleIds.length) {

        callback(new Error("管理平台模块必选"));
      } else if (!this.form.appModuleIds || !this.form.appModuleIds.length) {
        callback(new Error("App模块必选"));
      } else {
        callback();
      }
    },
    // 选择模板子组件传入的数据
    setTemplateId(item) {
      this.template = item || {};
      this.form.templateId = item.id || "";
    },
    // 选择地区回调
    areaChange(val) {
      this.form.provinceId = val[0] || "";
      this.form.cityId = val[1] || "";
      this.form.areaId = val[2] || "";
    },
    // 选择行业
    industrysIdChange(val) {
      this.form.industrysManageId = null;
      this.resetIndustrysManage(val);
    },
    //重置二级选项
    resetIndustrysManage(id) {
      this.industrysManage = [];
      var arr = this.industry.filter((item) => {
        return item.id === id;
      });
      if (arr && arr.length) {
        this.industrysManage = arr[0].children;
      } else {
        this.industrysManage = [];
      }
    },
    // 递归判断列表，把最后的children设为undefined
    clearTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.clearTreeData(data[i].children);
        }
      }
      return data;
    },
    // 组装树型数据
    setTree(treeData, parentId) {
      var treeArr = [];
      for (var i = 0; i < treeData.length; i++) {
        var node = treeData[i];
        if (node.pid == parentId) {
          var newNode = {
            id: node.id,
            label: node.name,
            children: this.setTree(treeData, node.id),
          };
          treeArr.push(newNode);
        }
      }
      return treeArr;
    },
    // 获取地区选项
    async getArea() {
      var res = await this.$get("/platform-admin/region/tree");
      if (res && res.code === 0) {
        var arr = this.setTree(res.data, "0");
        this.area = this.clearTreeData(arr);
        console.log(arr);
      }
    },
    // 获取行业选项
    async getIndustry() {
      var res = await this.$get("/platform-config/industry/tree");
      if (res && res.code === 0) {
        this.industry = this.clearTreeData(res.data);
        // 重置二级行业选项
        if (this.form.id) {
          this.resetIndustrysManage(this.form.industrysId);
        }
      }
    },

    // 获取当前时间
    getTime() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() - 0 + 1;
      month = month < 10 ? "0" + month : month;
      var day = date.getDate();
      day = day < 10 ? "0" + day : day;

      var str = year + "-" + month + "-" + day;
      return str;
    },
    initForm() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.form = {
        accountType: 0,
        pointsType: 0,
        status: 0,
        activeStaff: null,
        address: "",
        areaId: null,
        certificateImages: "",
        cityId: null,
        companyAbbreviation: "",
        companyLogo: "",
        companyName: "",
        createDate: "",
        creator: null,
        delFlag: null,
        duty: "",
        enterprisesOwners: "",
        showSlideshowQuantity: 0,
        expiryDate: "",
        hasInit: null,
        id: null,
        industrysId: null,
        industrysManageId: null,
        pointsTypes: {},
        principalTelephone: "",
        provinceId: null,
        receiverTelephone: "",
        remark: "",
        socialUniformCoding: "",
        softwareConnector: "",
        startDate: this.getTime(),
        templateId: null,
        tenantCode: null,
        updateDate: "",
        updater: null,
        userId: null,
        userType: null,
        organizationType: 1,
        username: "",
        adminName: "",
        moduleIds: [],
        appModuleIds: [],
        biller:''
      };
      // 选择的行业二级-id
      this.industrysManage = [];
      // 选择的地区ids
      this.areaIds = [];
      this.template = {};
      if (this.id) {
        this.sel();
      }
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          var data = { ...this.form };
          if (data.id) {
            var res = await this.$put("/platform-admin/tenant", data);
          } else {
            var res = await this.$post("/platform-admin/tenant", data);
          }
          this.loading = false;
          if (res && res.code === 0) {
            var str = data.id ? "修改成功" : "添加成功";
            var ev = data.id ? "edit" : "add";
            this.$message.success(str);
            this.$router.push("/user-list");
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader {
  width: 185px;
}

.avatar-uploader /deep/ {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}

/deep/ .el-form-item {
  min-width: 420px;
}

.dialog-footer {
  padding-left: 130px;
}

.module /deep/ .el-form-item__content {
  width: calc(100% - 150px);
}
</style>
